import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16')
];

export const server_loads = [0];

export const dictionary = {
		"/(landing)": [~10,[3]],
		"/(app)/dashboard": [~5,[2]],
		"/(app)/dashboard/analytics": [6,[2]],
		"/(app)/dashboard/content": [7,[2]],
		"/(app)/dashboard/settings": [~8,[2]],
		"/(app)/dashboard/support": [~9,[2]],
		"/(landing)/(auth)/forgot-password": [~11,[3,4]],
		"/(landing)/(legal)/privacy-policy": [16,[3]],
		"/(landing)/(auth)/reset-password": [~12,[3,4]],
		"/(landing)/(auth)/signin": [~13,[3,4]],
		"/(landing)/(auth)/signout": [14,[3,4]],
		"/(landing)/(auth)/signup": [~15,[3,4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';
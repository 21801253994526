import * as Sentry from "@sentry/sveltekit";
import { env } from '$env/dynamic/public';

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
Sentry.init({
    dsn: "https://39fa8637324f6ee73ead8298a21ddd6c@o4507556628135936.ingest.us.sentry.io/4507556631805952",
    tracesSampleRate: 1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.replayIntegration()],
    environment: env.PUBLIC_STAGE,
})

export const handleError = Sentry.handleErrorWithSentry();